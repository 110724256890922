<template>
  <div class="store">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <b-row>
          <b-dropdown size="sm" right>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <b>Thao tác</b>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <i style="font-size: 1rem" class="far fa-file-excel mr-1"></i>
              <span>Xuất Excel</span>
            </b-dropdown-item>
          </b-dropdown>
          <router-link
            to="/stores/add-store"
            v-show="checkPermission('STORE_INSERT')"
            class="ml-3"
          >
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
            </b-button>
          </router-link>
        </b-row>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col md="2">
            <b-input
              placeholder="Tìm kiếm"
              v-model="apiParams.searchName"
              size="sm"
              @keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="2">
            <b-form-select
              size="sm"
              v-model="apiParams.cityId"
              :options="optionsCities"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Thành phố</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col md="2">
            <b-form-select
              size="sm"
              v-model="apiParams.storeType"
              :options="[
                { id: 1, name: 'Showroom DDV' },
                { id: 2, name: 'Showroom VDD' },
                { id: 3, name: 'Mobifone' },
                { id: 4, name: 'Kho' },
              ]"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Loại cửa hàng</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col md="2">
            <b-form-select
              size="sm"
              v-model="apiParams.areaId"
              :options="optionsAreas"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Chọn khu vực</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col md="2">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
              >Lọc</b-button>
          </b-col>
        </b-row>
        <b-table
          :items="stores"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
          </template>
          <template v-slot:cell(name)="row">
            <span class="d-block">{{ row.item.name }}</span>
            <b v-if="row.item.storeTypeName">({{ row.item.storeTypeName }})</b>
          </template>
          <template v-slot:cell(isActive)="row">
              <span type="button"
              @click="onSwitchActiveStore(row.item, row.index)"
              :class="getBadge(row.item.isActive).class"
              v-text="getBadge(row.item.isActive).text"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon2-box-1"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Cập nhật
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('STORE_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItem }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav
                class="custom-pagination"
                v-if="numberOfPage >= 2"
                :link-gen="linkGen"
                :number-of-pages="numberOfPage"
                use-router
                @change="fetchStore"
                align="right"
                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                last-class="page-item-last btn btn-icon btn-sm my-1 "
                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
              >
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
    <StoreExcel :param="apiParams" />
  </div>
</template>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>
<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import Swal from 'sweetalert2';
import { VclTable } from 'vue-content-loading';


import SavaDataUtil from '@/utils/saveDataToLocal';
import localData from '@/utils/saveDataToLocal';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

import KTCodePreview from '@/view/content/CodePreview.vue';
import StoreExcel from '@/view/components/stores/StoreExcel';
import { IS_ACTIVE_NAME } from '@/utils/enum-name';
export default {
  data() {
    return {
      title: null,
      stores: [],
      optionsCities: [],
      listFeature: [],
      onLoading: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { textAlign: 'center', width: '6%' },
        },
        {
          key: 'name',
          label: 'Tên cửa hàng',
          sortable: false,
          thStyle: { width: '15%' },
        },
        {
          key: 'shortName',
          label: 'Tên viết tắt',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'address',
          label: 'Địa chỉ',
          sortable: false,
          thStyle: { width: '20%' },
        },
        {
          key: 'districtName',
          label: 'Quận',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'cityName',
          label: 'Thành phố',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'companyName',
          label: 'Doanh nghiệp',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'areaName',
          label: 'Khu vực',
          sortable: false,
          thStyle: { width: '8%' },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { width: '20%' },
        },
        { key: 'actions', label: '', thStyle: { width: '10%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      totalItem: 0,
      listCompany: [],
      optionsAreas: [],
      apiParams: {
        storeType: null,
        cityId: null,
        searchName: null,
        areaId: null,
      },
      IS_ACTIVE_NAME
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    StoreExcel,
  },
  methods: {
    async onSwitchActiveStore(storeInfo, index) {
      try {
        const data = {
          id: storeInfo.id,
          isActive: !storeInfo.isActive,
        };
        const {
          data: { message },
        } = await ApiService.put(`stores`, { data });
        this.stores[index].isActive = Number(data.isActive)
        this.makeToastSuccess(message);
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        this.makeToastFaile(message);
      }
    },
    getBadge(isActive) {
      return {
        text: this.IS_ACTIVE_NAME[isActive],
        class: isActive 
          ? 'label font-weight-bold label-lg label-light-success label-inline'
          : 'label font-weight-bold label-lg label-light-danger label-inline'
      }
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('STORE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('STORE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    editItem(item) {
      this.$router.push({
        path: '/stores/edit-store',
        query: { id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cửa hàng!',
        text: 'Bạn có chắc muốn xóa cửa hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteStore(item.id);
        }
      });
    },

    fetchStore() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      this.stores = [];

      const params = {
        limit: 10,
        page: this.page,
        companyId: 3,
        ...this.apiParams,
      };

      ApiService.query(`stores`, { params })
        .then(({ data }) => {
          this.stores = data.data.list_store;
          this.numberOfPage = data.data.total_page;
          this.totalItem = data.data.total_row;
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    fetchCity() {
      this.optionsCities = [];
      ApiService.get('city/').then((reponse) => {
        this.optionsCities = reponse.data.data;
      });
    },
    deleteStore(id) {
      ApiService.setHeader();
      ApiService.post(`stores/delete/${id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchStore();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-store',
      });
      this.getListFeature();
      this.fetchStore();
    },
    fetchArea: async function () {
      this.optionsAreas = [];
      ApiService.get('/area').then((response) => {
        this.optionsAreas = response.data.data;
      });
    },
    downloadExcel() {
      this.$bvModal.show('modal-store-excel');
    },
  },
  async created() {
    this.fetchCity();
    this.getListFeature();
    this.fetchStore();
    this.fetchArea();
  },
  mounted() {
    this.title = 'Danh sách cửa hàng';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cửa hàng', route: '/stores' },
      { title: 'Danh sách cửa hàng' },
    ]);
  },
};
</script>
<style lang="scss" scoped>
.store {
  input.form-control {
    border: 1px solid #ced4da;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
}
</style>
